import React from "react";
import BannerBackground from "../Assets/home-banner-background.png";
import BannerImage from "../Assets/home-banner-image.png";
import Navbar from "./Navbar";
import { FiArrowDown, FiArrowRight } from "react-icons/fi";


const Home = () => {
  return (
    <div className="home-container">
      <Navbar />
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src={BannerBackground} alt="" />
        </div>
        <div className="home-text-section">
          <h3 className="primary-heading">
          Your&nbsp;Spiritual&nbsp;Guruji
          </h3>
          <p className="primary-text">
          It is not necessarily tied to any particular religion or belief system, but rather a personal quest for understanding and connection with the universe.
          </p>
          <a href="https://play.google.com/store/apps/details?id=com.axelbuzz.vilasguruji" style={{ textDecoration: 'none' }}>
           <button className="secondary-button">Download app <FiArrowDown /></button></a>
        </div>
        <div className="home-image-section">
          <img src={BannerImage} alt="" />
        </div>
      </div>
    </div>
  );
};

const shoot = () => {
  alert("Great Shot!");
}

export default Home;
