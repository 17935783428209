import React from "react";
import Logo from "../Assets/Logo.svg";
import { BsTwitter } from "react-icons/bs";
import { SiLinkedin } from "react-icons/si";
import { BsYoutube } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { WhatsApp } from "@mui/icons-material";
import { FiMail, FiPhoneCall } from "react-icons/fi";

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer-section-one">
        <div className="footer-logo-container">
          <h2>Vilas&nbsp;&nbsp;Guruji</h2>
          {/* <img src={Logo} alt="" /> */}
        </div>
        <div className="footer-icons">
          {/* <BsTwitter /> */}
          <a href="https://wa.me/9822517316"><WhatsApp /></a>
          <a href="https://www.youtube.com/@vilasguruji"><BsYoutube /></a>
          <a href="https://www.facebook.com/vilas.parakh.5"><FaFacebookF /></a>
        </div>
      </div>
      <div className="footer-section-two">
        
        <div className="footer-section-columns">
          <a href="tel:+919822517316" style={{ textDecoration: 'none' }}><span><FiPhoneCall />&nbsp;&nbsp;&nbsp;&nbsp;Call</span></a>
          <a href="mailto:vilas.parakh@gmail.com" style={{ textDecoration: 'none' }}> <span><FiMail />&nbsp;&nbsp;&nbsp;&nbsp;Send email</span></a>
        </div>
        <div className="footer-section-columns">
          <span>Terms & Conditions</span>
          <span>Privacy Policy</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
