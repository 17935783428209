import React from "react";
import AboutBackground from "../Assets/about-background.png";
import AboutBackgroundImage from "../Assets/about-background-image.png";
import { BsFillPlayCircleFill } from "react-icons/bs";

const About = () => {
  return (
    <div className="about-section-container">
      <div className="about-background-image-container">
        <img src={AboutBackground} alt="" />
      </div>
      <div className="about-section-image-container">
        <img src={AboutBackgroundImage} alt="" />
      </div>
      <div className="about-section-text-container">
        {/* <p className="primary-subheading">About</p> */}
        <h1>
        Experiences of spiritual awakening and transformation
        </h1>
        <p className="primary-text">
        The journey towards discovering the deeper meaning and purpose of life, beyond the material world. It involves exploring the nature of consciousness, the interconnectedness of all things, and the existence of a higher power or universal energy.
        </p>
        <p className="primary-text">
        At its core, spirituality is about cultivating inner peace, happiness, and fulfillment through practices such as meditation, prayer, mindfulness, and self-reflection.
        </p>
        <div className="about-buttons-container">
          {/* <button className="secondary-button">Learn More</button> */}
          <a href="https://youtu.be/iAtdNnOkJGY" style={{ textDecoration: 'none' }}><button className="secondary-button">
            <BsFillPlayCircleFill />&nbsp;&nbsp;Watch&nbsp;Video
          </button></a>
        </div>
      </div>
    </div>
  );
};

export default About;
