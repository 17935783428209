import React from "react";
import PickMeals from "../Assets/muhurt.png";
import ChooseMeals from "../Assets/puja_vidhi.png";
import DeliveryMeals from "../Assets/aarti_sangrah.png";

const Work = () => {
  const workInfoData = [
    {
      image: PickMeals,
      title: "Muhurt",
      text: "",
    },
    {
      image: ChooseMeals,
      title: "Puja Vidhi",
      text: "",
    },
    {
      image: DeliveryMeals,
      title: "Aarti Sangrah",
      text: "",
    },
  ];
  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <p className="primary-subheading">Work</p>
        <h1 className="primary-heading">How It Works</h1>
        <p className="primary-text">
          Lorem ipsum dolor sit amet consectetur. Non tincidunt magna non et
          elit. Dolor turpis molestie dui magnis facilisis at fringilla quam.
        </p>
      </div>
      <div className="work-section-bottom">
        {workInfoData.map((data) => (
          <div className="work-section-info" key={data.title}>
            <div className="info-boxes-img-container">
              <img src={data.image} alt="" />
            </div>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Work;
